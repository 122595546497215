import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FaIcon } from "@/components/icons/FaIcon/FaIcon";
import "./sectionTableCardNewLink.scss";

type SectionTableCardNewLinkProps = {
    to: string;
    children?: React.ReactNode;
};

export function SectionTableCardNewLink(props: SectionTableCardNewLinkProps) {
    return (
        <Link to={props.to} className="section-table-card-new-link">
            <FaIcon icon={faPlus} spacing="right" spacingSize="1rem" />
            {props.children}
        </Link>
    );
}
